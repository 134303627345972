<template>
  <b-img
      height="30"
      :src="imgSrc"
      alt="logo"
  />
</template>

<script>

import { BImg } from 'bootstrap-vue'

export default {
  name: 'Logo',
  components: { BImg },
  computed: {
    imgSrc() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/images/logo/${process.env.VUE_APP_LOGO_BRAND}`)
    },
  },
}
</script>
